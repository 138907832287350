<template>
    <teleport to="#pageTitle">Skills</teleport>
    <teleport to="#pageSubTitle">My toolbox</teleport>

    <SkillsItem v-for="skill in skills" :key="skill.name" :skill="skill"></SkillsItem>

</template>

<script>
import SkillsItem from "./SkillsItem.vue";
import skills from "../data/skills";

export default {
    name: "SkillsPage",
    components: { SkillsItem },
    data() {
        return { skills };
    }
};
</script>