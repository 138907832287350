<template>
  <div>
    <div class="l-canvas type_wide col_cont headerpos_fixed headertype_extended">
      <div class="l-canvas-h">
        <Header></Header>
        <div class="l-main">
          <div class="l-main-h">
            <!-- <div class="l-submain"> -->
            <div class="l-submain-h g-html i-cf">
              <div class="l-submain for_pagehead">
                <div class="l-submain-h g-html i-cf">
                  <div class="w-pagehead">
                    <h1 id="pageTitle"></h1>
                    <p id="pageSubTitle"></p>
                  </div>
                </div>
              </div>

              <div class="l-submain">
                <div id="pageMain" class="l-submain-h g-html i-cf">
                  <router-view></router-view>
                </div>
              </div>
            </div>
            <!-- </div> -->
          </div>
        </div>
      </div>
    </div>

    <Footer></Footer>

    <a class="w-toplink" href="#">
      <i class="fa fa-angle-up"></i>
    </a>
  </div>
</template>

<script>
import Footer from './shared/Footer.vue';
import Header from './shared/Header.vue';

export default {
  name: "App",
  data() {
    return {
      value: "My Vue Site"
    };
  },
  components: { Footer, Header }
};
</script>

<style>
body,
p,
td {
  font-size: 16px;
}
.router-link-active {
  color: #1abc9c !important;
  font-weight: bold;
}
</style>
