const projects = [
  {
    "Title": "Personal Vue.js Site",
    "Description": "Create my personal website using Vue.js and Azure Static Web Apps.<br/>Used as a playground of modern JS framework ans Azure cloud services.",
    "Links": "https://velyo.net",
    "Started On": "Dec 2021",
    "Finished On": "Present",
    "Role": "Full Stack Developer",
    "Techs": ["Vue.js", "Node.js", "Webpack", "Azure Static Web Apps", "Git", "Visual Studio Code"]
  },
  {
    "Title": "Social platform for an American photography, photography products, and image sharing company.",
    "Description": "Content sharing websites that are free, fun, and easy to make.<br/>They're a great way to privately share photos with friends and family, a sports team, a class, or other group.\n\nIt's a breeze to manage your team, class, or group with the content sharing.\n\nShare photos and access your calendar - anywhere, anytime.<br/>Easily make last minute updates to the calendar, and send notices to your site members right from the app.<br/>Share and view photos and stay connected with teammates, classmates, friends, and family while on the go.\n\nThe app and website are both kept in sync when you make updates.\n\nKEY FEATURES: \n* News: View the latest activity on your site.<br/>\n* Photos: Share pictures and view pictures posted by others.<br/>\n* Calendar: Manage your site calendar. View date, time, and location of each event.<br/>\n* Maps: Get directions to a game or other event.<br/>\n* Members: Access your member list and add new members on the go.<br/>\n* Key Contacts: Access important contacts. Click a phone number to call or text.<br/>\n* Roster / Class List: See the list of players / students and access parent contact info.<br/>\n* Snack Schedule: View the snack schedule for the season, and sign up to bring food or drinks.<br/>\n* Sign-up Sheets: Access your sign-up sheets on the go and sign up for volunteer activities, driving duties, booth shifts, or to bring items or food.<br/>\n* Availability: See how many players you will have for your next game and update on the go.<br/>\n* Email: Easily send emails to your site members.",
    "Links": "",
    "Started On": "January 2018",
    "Finished On": "Present",
    "Role": "Team Lead & Senior Full Stack .NET Developer",
    "Techs": ["ASP.NET", "MongoDB", "AWS", "Git", "Jenkins", "Terraform", "IIS", "Visual Studio", "MSTest", "JIRA"]
  },
  {
    "Title": "Thebigword Project",
    "Description": "Interpreter Management System Direct Client (IMS.Direct).\nMS.Direct Client is fast, simple and available 24/7/365 to make interpreter bookings.\n\nProfessional interpreting, translation and localization services trusted by the top 100 global brands and organizations.<br/>Provides the best translation, interpretation, localization and language technology solutions for businesses, the public sector and individuals - handling up to 50,000 worldwide assignments every day.",
    "Links": "https://en-gb.thebigword.com/",
    "Started On": "November 2016",
    "Finished On": "December 2017",
    "Role": "Team Lead & Senior Full Stack .NET Developer",
    "Techs": ["ASP.NET MVC", "WCF", "MS SQL", "Entity Framework", "Telerik Kendo", "jQuery", "Simple Injector", "AutoMapper", "SVN", "IIS", "Visual Studio", "NUnit", "TargetProcess"]
  },
  {
    "Title": "MartinBros ERP & B2B",
    "Description": "ERP & B2B Java Enterprise Applications for Martin Bros Distributing.<br/>Martin Bros Distributing is one of the leading independent food distributors in the Midwest.<br/>For over 70 years, we've been proud to be the family-owned foodservice and supply solution for restaurants, healthcare facilities, schools and convenience stores throughout Iowa, Illinois, Wisconsin, Eastern Nebraska, Northern Missouri and Minnesota.",
    "Links": "https://www.martinbros.com/",
    "Started On": "September 2013",
    "Finished On": "July 2016",
    "Role": "Team Lead & Senior Full Stack Java Developer",
    "Techs": ["Java 8 EE", "MSSQL", "MySQL", "jQuery", "KnockOutJS", "Dynamic Reports", "Eclipse"]
  },
  {
    "Title": "Manager Mate",
    "Description": "Internal company project. Worked on REST services for data collecting and updates.",
    "Links": "",
    "Started On": "June 2013",
    "Finished On": "August 2013",
    "Role": "Senior Full Stack .NET Developer",
    "Techs": ["ASP.NET MVC & Web API", "Entity Framework", "MSSQL", "jQuery", "Visual Studio"]
  },
  {
    "Title": "Benefit Analysis Tool for Principal Financial Group",
    "Description": "Custom web application for employee benefit analysis.",
    "Links": "",
    "Started On": "April 2013",
    "Finished On": "June 2013",
    "Role": "Senior Full Stack .NET Developer",
    "Techs": ["ASP.NET MVC", "Entity Framework", "MSSQL", "jQuery", "iTextSharp", "Visual Studio"]
  },
  {
    "Title": "AAN Concussion Quick Check",
    "Description": "Hybrid Mobile Application (HTML5, CSS3, jQuery Mobile, KnockoutJS) build with Apache Cordova for American Academy of Neurology.\n\nThis App is provided by The American Academy of Neurology (AAN), the world's largest association of neurologists, who is the trusted authority in managing sports concussion. The Concussion Quick Check App can help coaches, athletic trainers, doctors, parents and athletes quickly evaluate if someone may have a concussion, head or brain injury and needs to see a licensed health care provider, such as a neurologist, who is specialized in concussion.\n\nKey information and tools in the 'Concussion Quick Check' app include:\n- Common signs of concussion;\n- Symptoms of concussion;\n- Things the athlete may tell you;\n- What to do if an athlete has a head injury during a game;\n- What to do if it appears the athlete has a concussion;\n- When an athlete should return to the game;\n- Help finding a neurologist near you (GPS capability);\n- Help finding state laws on concussion;\n- More about the American Academy of Neurology's new guideline for diagnosing, treating and managing sports concussion",
    "Links": [
      {
        name: "Website",
        url: "https://www.aan.com/"
      },
      {
        name: "Release Article",
        url: "http://www.medicalnewstoday.com/articles/257810.php"
      },
      {
        name: "Android App",
        url: "https://play.google.com/store/apps/details?id=com.concussion.aan&hl=bg&gl=US"
      },
      {
        name: "iOS App",
        url: "https://apps.apple.com/us/app/concussion-quick-check/id613178630"
      }
    ],
    "Started On": "December 2012",
    "Finished On": "March 2013",
    "Role": "Senior Full Stack Developer",
    "Techs": ["Apache Cordova", "HTML5", "CSS3", "jQuery Mobile", "KnockoutJS", "Visual Studio", "Eclipse", "XCode"]
  },
  {
    "Title": "Christmas Ornaments",
    "Description": "Online shop for selling Christmas ornaments.<br/>At www.ChristmasOrnaments.com you may shop knowing that the gifts and decorations you select will be not only beautiful, but also made with the finest quality materials.",
    "Links": "https://www.christmasornaments.com/",
    "Started On": "January 2010",
    "Finished On": "January 2016",
    "Role": "Senior Full Stack .NET Developer",
    "Techs": ["ASP.NET", "ASP.NET MVC", "Entity Framework", "AutoMapper", "jQuery", "KnockOutJS", "MS SQL", "Windows Services", "Mercurial", "Visual Studio"]
  },
  {
    "Title": "AxeFrog Systems Ltd Web Project",
    "Description": "Custom ASP.NET MVC project for AxeFrog Systems Ltd. Worked as freelance ASP.NET MVC developer",
    "Links": "",
    "Started On": "November 2010",
    "Finished On": "July 2011",
    "Role": "Senior ASP.NET MVC Developer",
    "Techs": ["ASP.NET MVC", "jQuery", "Mercurial", "Visual Studio"]
  },
  {
    "Title": "ASP.NET Tiny Security Providers",
    "Description": "ASP.NET Tiny Security Providers is a free, open source, pack of tiny ASP.NET Membership, Role and Profile providers.<br/>The goal of this project is to provide custom implementation of ASP.NET providers against tiny data storages (XML, SQL Compact etc.).\n\nFeatures: \n* Native support and usage by all ASP.NET membership controls; \n* Supports all password formats: \n* Clear, Encrypted and Hashed; \n\nTiny Security Providers best fit for sites with a small number of authenticated users and hosted without database access.<br/>It is not recommended to use the providers with \"heavy-duty\" membership sites (a lot of roles, users and big number of members), because of the tiny data storage. \n\nThe project was initially hosted in [Microsoft CodePlex](https://en.wikipedia.org/wiki/CodePlex) and after the service sunset moved to GitHub in 2015.",
    "Links": "https://github.com/velyo/aspnet-tiny-security",
    "Started On": "September 2010",
    "Finished On": "November 2010",
    "Role": "Founder / .NET Developer",
    "Techs": ["ASP.NET", "Mercurial", "Visual Studio"]
  },
  {
    "Title": "Jugnu Inc Web Project",
    "Description": "Custom ASP.NET MVC project for Jugnu Inc. Worked as freelance ASP.NET MVC 2 developer.",
    "Links": "",
    "Started On": "August 2010",
    "Finished On": "October 2010",
    "Role": "Senior ASP.NET MVC Developer",
    "Techs": ["ASP.NET MVC", "jQuery", "MS SQL", "Mercurial", "Visual Studio"]
  },
  {
    "Title": "VisualStudio JavaScript & CSS Outlining",
    "Description": "Visual Studio editor extension for JavaScript & CSS code blocks and custom regions outlining.\n\nAs a web developer, I found myself dealing with fast growing JavaScript and CSS code files.<br/>And I really missed the code regions in Visual Studio, which is a nice way to organize the code blocks and focus on reasonable small amount of code. \n\nThere are some macros available out on the web for placing a regions outlining in JavaScript code editor.<br/>I was not fully satisfied with macros and the fact I have to manually run them (even with a shortcut keys) every time a JavaScript file is loaded.\n\nThus, I spent some time and came out with a Visual Studio 2010 Editor Extension for JavaScript & CSS Outlining.\n\nThe project was initially hosted in [Microsoft CodePlex](https://en.wikipedia.org/wiki/CodePlex) and after the service sunset moved to GitHub in 2015.",
    "Links": "https://github.com/velyo/vs-outlining",
    "Started On": "June 2010",
    "Finished On": "July 2010",
    "Role": "Founder / .NET Developer",
    "Techs": ["ASP.NET", "Mercurial", "Visual Studio"]
  },
  {
    "Title": "SEEEMS.CMS",
    "Description": "Content management system build on ASP.NET 4.0 Web Forms and Web Controls with custom control tree render.\n\n NoSQL by default, it is using a file storage system (XML) to keep and manage the website content, but based on provider pattern can switch to any other data storage.\n\n Flexible and extendable component centric architecture, making possible to add and manage any custom content.",
    "Links": "http://www.seeems.com/",
    "Started On": "July 2009",
    "Finished On": "October 2012",
    "Role": "Senior .NET Developer",
    "Techs": ["ASP.NET WebForm", "ASP.NET MVC", "JavaScript", "jQuery", "Visual Studio"]
  },
  {
    "Title": "SEEEMS.CMS Rebuild",
    "Description": "Porting the SEEEMS content management application built with ASP to .NET Framework and ASP.NET.",
    "Links": "http://www.seeems.com/",
    "Started On": "April 2009",
    "Finished On": "June 2009",
    "Role": "Senior .NET Developer",
    "Techs": ["ASP.NET WebForm", "JavaScript", "jQuery", "Visual Studio"]
  },
  {
    "Title": "Wired Solutions Web Projects",
    "Description": "Custom ASP.NET project for Wired Solutions. Worked on various projects as freelance ASP.NET developer.",
    "Links": "http://wiredsolutions.ca/",
    "Started On": "Sep 2008",
    "Finished On": "Mar 2010",
    "Role": "Advanced ASP.NET/C# Developer",
    "Techs": ["ASP.NET", "jQuery", "KnockOutJS", "MS SQL", "TFS", "Visual Studio"]
  },
  {
    "Title": "Ornament Shop",
    "Description": "Online shop selling custom decorated and personalized holidays ornaments.<br/>Proudly selling Christmas decorations and gifts since 1982 and it's 20th Christmas season of selling personalized Christmas ornaments on the Internet.",
    "Links": "https://www.ornamentshop.com/",
    "Started On": "Sep 2008",
    "Finished On": "Nov 2017",
    "Role": "Senior Full Stack .NET Developer",
    "Techs": ["ASP.NET", "ASP.NET MVC", "Entity Framework", "AutoMapper", "jQuery", "KnockOutJS", "MS SQL", "Windows Services", "Mercurial", "Visual Studio"]
  },
  {
    "Title": "Feedback System",
    "Description": "Web application framework to build custom feedback and survey forms.<br/>The application allows companies to build flexible surveys for their needs.",
    "Links": "https://feedbacksystem.dk/",
    "Started On": "Jun 2008",
    "Finished On": "Mar 2009",
    "Role": "Senior .NET Developer",
    "Techs": ["ASP.NET", "MS SQL", "SourceSafe", "Visual Studio"]
  },
  {
    "Title": "GoogleMap Control",
    "Description": "Wraps Google Maps API as ASP.NET custom control.<br/>Offers extremely easy and fast way of adding Google Maps API support on your ASP.NET pages.",
    "Links": "https://github.com/velyo/google-maps",
    "Started On": "Aug 2007",
    "Finished On": "October 2007",
    "Role": "Founder / .NET Developer",
    "Techs": ["ASP.NET", "Mercurial", "Visual Studio"]
  },
  {
    "Title": "ASP.NET XML Providers",
    "Description": "ASP.NET XML Providers (XmlRoleProvider, XmlMembershipProvider and XmlProfileProvider) is an evolution pack of articles and code samples, improved to provide membership provider implementation against a tiny NoSQL (XML) storage.",
    "Links": "https://aspnetxmlproviders.codeplex.com/",
    "Started On": "April 2007",
    "Finished On": "July 2007",
    "Role": "Founder / .NET Developer",
    "Techs": ["ASP.NET", "Mercurial", "Visual Studio"]
  },
  {
    "Title": "Custom ASP.NET Websites",
    "Description": "Custom ASP.NET websites development. Worked on variety of custom ASP.NET Web Forms application.",
    "Links": "",
    "Started On": "July 2006",
    "Finished On": "May 2008",
    "Role": ".NET Developer",
    "Techs": ["ASP.NET", "MS SQL", "Visual Studio"]
  },
  {
    "Title": "Recipe Manager",
    "Description": "Farmers feeding and recipe planning application for Windows built with MS Visual C++.",
    "Links": "",
    "Started On": "March 2006",
    "Finished On": "June 2006",
    "Role": "C++ Developer",
    "Techs": ["Visual C++ (including ATL, MFC, OLE DB Templates)", "MySQL", "Visual Studio"]

  },
  {
    "Title": ".NET Data Access",
    "Description": ".NET Framework 2.0 ORM implementation.\n\n Based on providers architecture, it has a powerful built-in object fetching with properties and methods mapping, including BuildProvider for building strong type from a given database schema.\n\n Here is the list of already implemented providers: MSSQL Provider, Oracle Provider, Firebird Provider, MySQL Provider, OLE DB Provider, ODBC Provider.\n\nIt has been used more than 3 years as my universal tool to work with databases, before Microsoft issuing Entity Framework.",
    "Links": "https://github.com/velyo/dotnet-data-access",
    "Started On": "October 2005",
    "Finished On": "December 2005",
    "Role": "Founder / .NET Developer",
    "Techs": ["ASP.NET", "MS SQL", "ADO.NET", "ODBC", ".NET Data Access and ORM", "Visual Studio"]
  },
  {
    "Title": "E-Commerce Website",
    "Description": "Custom e-commerce web application for particular company to sell products online.",
    "Links": "https://www.consultcommerce.com/",
    "Started On": "October 2005",
    "Finished On": "March 2006",
    "Role": ".NET Developer",
    "Techs": ["ASP.NET", "MS SQL", "MySQL", "ADO.NET", "ODBC", ".NET Data Access and ORM", "Visual Studio"]
  },
  {
    "Title": "Custom CRM Application",
    "Description": "Custom CRM web application for a particular customer.",
    "Links": "https://www.consultcommerce.com/",
    "Started On": "Jul 2005",
    "Finished On": "Oct 2005",
    "Role": ".NET Developer",
    "Techs": ["ASP.NET", "MS SQL", "ADO.NET", "Visual Studio"]
  },
  {
    "Title": ".NET Libraries for Custom Projects",
    "Description": "ASP.NET Web Forms custom control libraries and .NET libraries built for a company wide usage.",
    "Links": "https://www.consultcommerce.com/",
    "Started On": "May 2005",
    "Finished On": "Jul 2005",
    "Role": ".NET Developer",
    "Techs": ["ASP.NET", "Visual Studio"]
  },
  {
    "Title": "ELAS Server / SPPX Services",
    "Description": "Smart security system feedback collection server and add-on services.<br/>Developing services to collect, process and dispatch data from a smart home security systems.",
    "Links": "",
    "Started On": "Mar 2004",
    "Finished On": "Oct 2004",
    "Role": ".NET Developer",
    "Techs": ["ASP.NET", "C++", "SOAP", "Visual Studio"]
  },
  {
    "Title": "Collaboration Business Portal Workflow Editor",
    "Description": "Workflow editor over PowerPoint COM libraries for creating collaboration and document workflows.",
    "Links": "https://www.fincore.com/",
    "Started On": "Sep 2003",
    "Finished On": "Mar 2004",
    "Role": ".NET Developer",
    "Techs": ["ASP.NET", "PowerPoint", "COM", "Visual Studio"]
  },
  {
    "Title": "Collaboration Business Portal Admin",
    "Description": "Admin web application for processing and managing collaboration portal data.",
    "Links": "https://www.fincore.com/",
    "Started On": "Nov 2002",
    "Finished On": "Aug 2003",
    "Role": ".NET Developer",
    "Techs": ["ASP.NET", "PowerPoint", "COM", "Visual Studio"]
  },
  {
    "Title": "Reuters Feed Service",
    "Description": "Reuters feeds Oracle embedded consumer for a financial application.<br/>Developing all the features of embedded consumer service.",
    "Links": "https://www.fincore.com/",
    "Started On": "Jul 2002",
    "Finished On": "Oct 2002",
    "Role": "Java Developer",
    "Techs": ["Java", "JDBC", "Oracle SQLJ", "Oracle JPublisher", "Java Mail", "Borland JBuilder"]
  },
  {
    "Title": "MarginMaker FirePay Integration",
    "Description": "Payment provider Oracle embedded integration for a financial application.<br/> Developing all the features of payment provider integration.",
    "Links": "https://www.fincore.com/",
    "Started On": "Apr 2002",
    "Finished On": "Jun 2002",
    "Role": "Java Developer",
    "Techs": ["Java", "JDBC", "Oracle Java Stored Procedures", "Oracle SQLJ Object Types", "Oracle SQLJ", "Oracle JPublisher", "Borland JBuilder"]
  },
  {
    "Title": "NPI ADO.NET Provider",
    "Description": "ADO.NET provider over publish/subscribe (Nomura bank) protocol.<br/>Developing all the features of ADO provider.",
    "Links": "https://www.fincore.com/",
    "Started On": "Jan 2002",
    "Finished On": "Mar 2002",
    "Role": ".NET Developer",
    "Techs": [".NET", "ADO.NET", "C++", "Visual Studio"]
  },
  {
    "Title": "Java Client Audio Media Managing System",
    "Description": "Java applet client for audio media managing services.<br/> Developing main functionality of treemap functionality.",
    "Links": "https://www.fincore.com/",
    "Started On": "Aug 2001",
    "Finished On": "Dec 2001",
    "Role": "Java Developer",
    "Techs": ["Java", "Java Applet", "Borland JBuilder"]
  },
  {
    "Title": "The White Label",
    "Description": "Music promotion web portal for online streaming of brand new music and DJ feedback collecting.<br/>The collected feedback is used to issue music collections on the music market.",
    "Links": "https://www.fincore.com/",
    "Started On": "Sep 2000",
    "Finished On": "Dec 2001",
    "Role": "Java Developer",
    "Techs": ["Java EE", "Servlet API", "JSP", "XSLT/Cocoon", "HTML", "CSS", "JavaScript", "Borland JBuilder"]
  },
  {
    "Title": "Windows Audio Media Managing System",
    "Description": "Windows services and libraries for managing and audio media and encoding it to Windows Media and QuickTime audio streaming formats.<br/>Developing all the features of the library and porting QuickTime SDK C code to .NET",
    "Links": "https://www.fincore.com/",
    "Started On": "Jan 2001",
    "Finished On": "Aug 2001",
    "Role": ".NET Developer",
    "Techs": [".NET Framework 1.0", ".NET Windows Forms", "Visual Studio"]
  },
  {
    "Title": "Finsoft Wave Market Map",
    "Description": "Develop treemap visualization applet for stocks financial data.",
    "Links": "https://www.fincore.com/",
    "Started On": "Apr 2000",
    "Finished On": "Sep 2000",
    "Role": "Java Developer",
    "Techs": ["Java", "Java Applet", "Borland JBuilder"]
  },
  {
    "Title": "Help Desk and Tickets Processing",
    "Description": "Custom windows desktop and network help desk solution.",
    "Links": "",
    "Started On": "Jun 1998",
    "Finished On": "Mar 2000",
    "Role": "Pascal Developer",
    "Techs": ["Pascal", "Visual Component Library (VCL)", "Pervasive(Btrieve) Database", "Novell NetWare", "Borland Borland Delphi"]
  }
];

export default projects;