<template>
    <div v-for="link in links" :key="link.name" class="w-socials-item" :class="link.name">
        <a class="w-socials-item-link" target="_blank" :href="link.url">
            <i class="fa" :class="'fa-' + link.name"></i>
        </a>
        <div class="w-socials-item-popup">
            <div class="w-socials-item-popup-h">
                <span class="w-socials-item-popup-text">{{link.title}}</span>
            </div>
        </div>
    </div>
</template>

<script>
import links from "../data/social-links";

export default {
    name: "SocialLinks",
    data() {
        return {
            links
        };
    }
};
</script>