<template>
    <p>
        <strong>
            <a :href="experience.link" target="_blank">{{ experience.title }}</a>
        </strong>
        <br />
        <em v-html="experience.description"></em>
    </p>

    <ul>
        <li v-for="item in experience.items" :key="item">
            {{ item.name }}
            <br />
            <em>{{ item.tech }}</em>
        </li>
    </ul>

    <div class="g-hr">
        <span class="g-hr-h">
            <i class="fa fa-star"></i>
        </span>
    </div>
</template>

<script>


export default {
    name: "ExperienceItem",
    props: ["experience"],
};
</script>
