const links = [
    {
        name: "github",
        title: "GitHub",
        url: "https://github.com/velyo"
    },
    {
        name: "stack-overflow",
        title: "Stack Overflow",
        url: "https://stackoverflow.com/users/210355/velyo"
    },
    {
        name: "linkedin",
        title: "LinkedIn",
        url: "https://www.linkedin.com/in/velyo-ivanov"
    },
    {
        name: "facebook",
        title: "Facebook",
        url: "https://www.facebook.com/bat.velko"
    },
    {
        name: "twitter",
        title: "Twitter",
        url: "https://twitter.com/velioivanov"
    }
];

export default links;