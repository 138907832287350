<template>
    <teleport to="#pageTitle">Videos</teleport>
    <teleport to="#pageSubTitle">Video media library</teleport>

    <p>Here are some screencasts and sample videos recorded by me.</p>

    <div id="projectsContent" class="l-content">
        <div class="l-content-h">
            <div class="w-blog imgpos_attop meta_all">
                <div class="w-blog-h">
                    <div class="w-blog-list">
                        <div class="w-blog-entry">
                            <div class="w-blog-entry-h">
                                <a class="w-blog-entry-link">
                                    <h2>GoogleMap Control Marker Manager</h2>
                                </a>
                                <div class="w-blog-entry-body">
                                    <div class="g-cols">
                                        <div class="one-third">
                                            <i class="fa fa-clock-o"></i>
                                            <span class="w-blog-entry-meta-date-month">2009-10-24</span>
                                        </div>
                                        <div class="two-thirds"></div>
                                    </div>

                                    <div class="w-blog-entry-short">
                                        <p>Shows how to use the MarkerManager to manage visibility of hundreds of markers on a map, based on the map's current viewport and zoom level.</p>
                                        <p>
                                            <a
                                                href="/videos/googlemapcontrol-using-the-markermanager"
                                            >&raquo; watch</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-blog-entry">
                            <div class="w-blog-entry-h">
                                <a class="w-blog-entry-link">
                                    <h2>GoogleMap Control Marker InfoWindow</h2>
                                </a>
                                <div class="w-blog-entry-body">
                                    <div class="g-cols">
                                        <div class="one-third">
                                            <i class="fa fa-clock-o"></i>
                                            <span class="w-blog-entry-meta-date-month">2009-04-25</span>
                                        </div>
                                        <div class="two-thirds"></div>
                                    </div>

                                    <div class="w-blog-entry-short">
                                        <p>GoogleMap Control sample of changes and new features for GoogleMarker InfoWindow.</p>
                                        <p>
                                            <a href="/videos/googlemarker-infowindow">&raquo; watch</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-blog-entry">
                            <div class="w-blog-entry-h">
                                <a class="w-blog-entry-link">
                                    <h2>GoogleMap Control Reverse Geocoding</h2>
                                </a>
                                <div class="w-blog-entry-body">
                                    <div class="g-cols">
                                        <div class="one-third">
                                            <i class="fa fa-clock-o"></i>
                                            <span class="w-blog-entry-meta-date-month">2009-04-10</span>
                                        </div>
                                        <div class="two-thirds"></div>
                                    </div>

                                    <div class="w-blog-entry-short">
                                        <p>GoogleMap Control sample of how to use new Reverse Geocoding feature.</p>
                                        <p>
                                            <a
                                                href="/videos/googlemap-control-reverse-geocoding-feature"
                                            >&raquo; watch</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-blog-entry">
                            <div class="w-blog-entry-h">
                                <a class="w-blog-entry-link">
                                    <h2>GoogleMap Control Address Not Found</h2>
                                </a>
                                <div class="w-blog-entry-body">
                                    <div class="g-cols">
                                        <div class="one-third">
                                            <i class="fa fa-clock-o"></i>
                                            <span class="w-blog-entry-meta-date-month">2009-04-09</span>
                                        </div>
                                        <div class="two-thirds"></div>
                                    </div>

                                    <div class="w-blog-entry-short">
                                        <p>GoogleMap Control sample of how to use new "Address Not Found" features.</p>
                                        <p>
                                            <a
                                                href="/videos/googlemap-control-address-not-found-features"
                                            >&raquo; watch</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-blog-entry">
                            <div class="w-blog-entry-h">
                                <a class="w-blog-entry-link">
                                    <h2>GoogleMap Control Using Skin</h2>
                                </a>
                                <div class="w-blog-entry-body">
                                    <div class="g-cols">
                                        <div class="one-third">
                                            <i class="fa fa-clock-o"></i>
                                            <span class="w-blog-entry-meta-date-month">2009-04-02</span>
                                        </div>
                                        <div class="two-thirds"></div>
                                    </div>

                                    <div class="w-blog-entry-short">
                                        <p>Shows how to use ASP.NET Skin with GoogleMap Control to manager its "Look&Feel".</p>
                                        <p>
                                            <a
                                                href="/videos/using-skin-to-manage-googlemap-control-look-feel"
                                            >&raquo; watch</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-blog-entry">
                            <div class="w-blog-entry-h">
                                <a class="w-blog-entry-link">
                                    <h2>ASP.NET MVC View - More than just a page view</h2>
                                </a>
                                <div class="w-blog-entry-body">
                                    <div class="g-cols">
                                        <div class="one-third">
                                            <i class="fa fa-clock-o"></i>
                                            <span class="w-blog-entry-meta-date-month">2009-03-10</span>
                                        </div>
                                        <div class="two-thirds"></div>
                                    </div>

                                    <div class="w-blog-entry-short">
                                        <p>Sample video to demo "ASP.NET MVC View is more than just a page view".</p>
                                        <p>
                                            <a
                                                href="/videos/aspnet-mvc-view-more-than-just-a-page-view"
                                            >&raquo; watch</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-blog-entry">
                            <div class="w-blog-entry-h">
                                <a class="w-blog-entry-link">
                                    <h2>GoogleMap Control Capture Position</h2>
                                </a>
                                <div class="w-blog-entry-body">
                                    <div class="g-cols">
                                        <div class="one-third">
                                            <i class="fa fa-clock-o"></i>
                                            <span class="w-blog-entry-meta-date-month">2009-03-07</span>
                                        </div>
                                        <div class="two-thirds"></div>
                                    </div>

                                    <div class="w-blog-entry-short">
                                        <p>How to capture position of a point with GoogleMap Control.</p>
                                        <p>
                                            <a
                                                href="/videos/googlemap-capture-position"
                                            >&raquo; watch</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-blog-entry">
                            <div class="w-blog-entry-h">
                                <a class="w-blog-entry-link">
                                    <h2>GoogleMap Control Geocoding</h2>
                                </a>
                                <div class="w-blog-entry-body">
                                    <div class="g-cols">
                                        <div class="one-third">
                                            <i class="fa fa-clock-o"></i>
                                            <span class="w-blog-entry-meta-date-month">2009-03-06</span>
                                        </div>
                                        <div class="two-thirds"></div>
                                    </div>

                                    <div class="w-blog-entry-short">
                                        <p>Shows how to use geocoding with GoogleMap Control.</p>
                                        <p>
                                            <a href="/videos/googlemap-geocoding">&raquo; watch</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="w-blog-entry">
                            <div class="w-blog-entry-h">
                                <a class="w-blog-entry-link">
                                    <h2>GoogleMap Control Setup</h2>
                                </a>
                                <div class="w-blog-entry-body">
                                    <div class="g-cols">
                                        <div class="one-third">
                                            <i class="fa fa-clock-o"></i>
                                            <span class="w-blog-entry-meta-date-month">2009-03-05</span>
                                        </div>
                                        <div class="two-thirds"></div>
                                    </div>

                                    <div class="w-blog-entry-short">
                                        <p>Shows how to setup GoogleMap Control and tips of common usage.</p>
                                        <p>
                                            <a href="/videos/googlemap-control-video">&raquo; watch</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "VideosPage"
};
</script>