<template>
    <div id="projectsContent" class="l-content">
        <div class="l-content-h">
            <div class="w-blog imgpos_attop meta_all">
                <div class="w-blog-h">
                    <div class="w-blog-list">
                        <div v-for="project in projects" :key="project.name" class="w-blog-entry">
                            <ProjectItem :project="project"></ProjectItem>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <teleport to="#pageTitle">Projects</teleport>
    <teleport to="#pageSubTitle">Some of my Projects</teleport>
</template>

<script>
import projects from "../data/projects";
import ProjectItem from "./ProjectItem.vue";

export default {
    name: "ProjectsPage",
    data() {
        return { projects };
    },
    components: { ProjectItem }
};
</script>

<style scoped>
.w-blog-entry:last-child {
    border-bottom: none;
}
ul.project-tags {
    display: inline;
    margin: 0;
    list-style: none;
}
ul.project-tags li {
    display: inline;
    padding-right: 2px;
}
ul.project-tags li::after {
    content: ",";
}
ul.project-tags li:last-child::after {
    content: "";
}
p {
    line-height: normal;
    margin-bottom: 10px;
}
</style>