<template>
    <div class="l-footer">
        <div class="l-footer-h">
            <!-- subfooter: top -->
            <div class="l-subfooter at_top">
                <div class="l-subfooter-h g-cols cols_fluid">
                    <div class="one-third">
                        <div class="widget">
                            <h4>Disclaimer</h4>

                            <p>
                                The opinions expressed herein are my own personal opinions and do not represent my
                                employer’s view in any way.
                            </p>
                        </div>
                    </div>

                    <div class="one-third">
                        <div class="widget">
                            <h4>More Info</h4>

                            <ul>
                                <li>
                                    <a href="/">Home</a>
                                </li>
                                <!-- <li>
                                    <a href="/bookmarks/">Bookmarks</a>
                                </li>
                                <li>
                                    <a href="/videos/">Videos</a>
                                </li>-->
                            </ul>
                        </div>
                    </div>

                    <div class="one-third">
                        <div class="widget">
                            <h4>Contact Info</h4>

                            <div class="w-contacts">
                                <div class="w-contacts-h">
                                    <div class="w-contacts-list">
                                        <div class="w-contacts-item for_address">
                                            <i class="icon-location"></i>
                                            <span class="w-contacts-item-name">Address:</span>
                                            <span
                                                class="w-contacts-item-value"
                                            >Plovdiv 4000, Bulgaria</span>
                                        </div>
                                        <div class="w-contacts-item for_email">
                                            <i class="icon-mail"></i>
                                            <span class="w-contacts-item-name">Email:</span>
                                            <span class="w-contacts-item-value" site-email>
                                                <a :href="emailAddressRef">{{ emailAddress }}</a>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="widget">
                            <div class="w-socials size_normal">
                                <div class="w-socials-h">
                                    <div class="w-socials-list">
                                        <SocialLinks></SocialLinks>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- subfooter: bottom -->
            <div class="l-subfooter at_bottom">
                <div class="l-subfooter-h i-cf">
                    <div class="w-copyright">
                        © Copyright {{ copyrightYear }}, Velio Ivanov.
                        <router-link to="/privacy">Privacy Statement</router-link>
                    </div>

                    <!-- NAV -->
                    <nav class="w-nav">
                        <div class="w-nav-h">
                            <div class="w-nav-list layout_hor width_auto float_right level_1">
                                <div class="w-nav-list-h">
                                    <div
                                        v-for="page in pages"
                                        :key="page.url"
                                        class="w-nav-item level_1 home"
                                    >
                                        <div class="w-nav-item-h">
                                            <router-link
                                                :to="page.url"
                                                class="w-nav-anchor level_1"
                                            >{{ page.name }}</router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sharedMixin from "./shared-mixin";
import SocialLinks from "./SocialLinks.vue";

export default {
    name: "Footer",
    data() {
        var date = new Date(Date.now());
        return {
            copyrightYear: date.getFullYear()
        };
    },
    mixins: [sharedMixin],
    components: { SocialLinks }
};
</script>