<template>
    <teleport to="#pageTitle">Bookmarks</teleport>
    <teleport to="#pageSubTitle">My Recent Bookmarks</teleport>

    <p>The bookmarks of interesting things found on the net.</p>

    <div id="bookmarks" data-bind="foreach: bookmarks">
        <div>
            &raquo;
            <a href data-bind="text: d, attr: {href: u}"></a> /
            <span data-bind="text: dt"></span>
        </div>
    </div>

    <hr />

    <div>
        <a class="w-iconbox-text-link" href="https://delicious.com/velio.ivanov" target="_blank">
            <span>Check all bookmarks</span>
        </a>
    </div>
</template>

<script>
export default {
    name: "BookmarksPage"
};
</script>