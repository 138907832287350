<template>
    <p>
        I do not sell, rent or loan any identifiable information to any third party.
        <br />Any information you give me is held with the utmost care and security, and will not be used in ways to which you have not consented.
        <br />I use cookies on this site only in purpose of the functionality and usability.
        <br />This statement details the steps I take to protect your personal information when you visit my websites.
        <br />It describes the personal information that I collect, the purposes for which I use such information, and your choices regarding my use of it.
        <br />The steps I take to protect your personal information and how you can review and correct your personal information are also covered here.
        <br />By accessing my websites, you are consenting to the information collection and use practices described in this privacy statement.
    </p>

    <h4>Comments</h4>

    <p>
        <!-- This site allows readers to post comments to almost all online content. But that doesn't give you the right to act like a jerk. -->
        <br />I will not delete comments based on their personal viewpoint. I may, however, delete comments that are off-topic, use excessive foul language, or employ slurs based on race, gender, religion, or sexual orientation.
        <br />I may also delete comments that make personal attacks on story subjects - especially if those attacks are unrelated to the content of the piece and are unsupported by factual evidence.
        <br />If you want to use the internet to settle personal scores, you might want to try starting your own blog.
    </p>

    <p>
        The fact that a comment remains on the site does not imply the endorsement of mine.
        <br />I make no representation as to the accuracy of any assertions made in the comments section.
        <br />I assume no liability for any statements made by third parties in the comments sections of its Web site.
    </p>

    <h4>Collection of information</h4>

    <p>
        <em>Information collected directly from you.</em>
        <br />Generally, you can visit my websites without entering any personal information.
        <br />On certain pages, I may ask you for personal information to provide a service that you have requested.
        <br />The personal information I collect may include:
    </p>

    <ul>
        <li>information that you provide the purpose of registering with the website;</li>
        <li>information that you provide for the purpose of subscribing to website services, email notifications and/or newsletters;</li>
        <li>feedback from you about the websites and products and services generally;</li>
        <li>any other information that you choose to send;</li>
    </ul>

    <p>
        You are not required to provide any of this information, but if you do not, I may not be able to provide you the requested service.
        <br />
        <br />
        <br />
        <em>Information collected automatically.</em>
        <br />
        <br />
        <br />I collect information about your visit to the website, including what pages you view, the number of bytes transferred, the links you click, the materials you access, and other actions taken within website.
        <br />I also collect certain standard information that your browser sends to every website you visit, such as your Internet Protocol (IP) address, your browser type and capabilities and language, your operating system, the date and time you access the site, and the website from which you linked to our site.
        <br />I do not link this information to your identity.
    </p>

    <h4>Use of information</h4>

    <p>
        <em>Website improvement.</em>
        <br />
        <br />
        <br />I may use your personal information to improve the website and related products or services, or to make the website easier to use by eliminating the need for you to repeatedly enter the same information or by customizing the website to your particular preference or interests.
        <br />
        <br />
        <br />
        <em>Communications.</em>
        <br />
        <br />
        <br />With your permission, I may use your personal information gathered via the website to inform you of products or services available. When collecting information that might be used to contact you about products and services, I will always give you the opportunity to opt-out of receiving such communications.
        <br />Moreover, each e-mail communication I send includes an unsubscribe link allowing you to stop delivery of that type of communication.
        <br />If you elect to unsubscribe, I will remove you from the relevant list.
    </p>

    <h4>Use of cookies</h4>

    <p>
        A cookie consists of information sent by a web server to a web browser, and stored by the browser.
        <br />The information is then sent back to the server each time the browser requests a page from the server.
        <br />This enables the web server to identify and track the web browser.
        <br />I may use both “session” cookies and “persistent” cookies on the website.
        <br />I will use the session cookies to: keep track of you whilst you navigate the website.
        <br />I will use the persistent cookies to: enable the website to recognise you when you visit.
        <br />Session cookies will be deleted from your computer when you close your browser.
        <br />Persistent cookies will remain stored on your computer until deleted, or until they reach a specified expiry date.
        <br />I use Google Analytics to analyse the use of this website. Google Analytics generates statistical and other information about website use by means of cookies, which are stored on users' computers.
        <br />The information generated relating to website is used to create reports about the use of the website.
        <br />Google will store this information.
        <br />Google's privacy policy is available at:
        <a href="http://www.google.com/privacypolicy.html" class="externalLink">
            <a
                href="http://www.google.com/privacypolicy.html"
            >http://www.google.com/privacypolicy.html</a>
        </a>.
        <br />Most browsers allow you to refuse to accept cookies.
        <br />(For example, in Internet Explorer you can refuse all cookies by clicking “Tools”, “Internet Options”, “Privacy”, and selecting “Block all cookies” using the sliding selector.)
        <br />This will, however, have a negative impact upon the usability of many websites, including this one.
    </p>

    <h4>Security</h4>

    <p>
        I'm committed to protecting the security of your personal information.
        <br />I use a variety of security technologies and procedures to help protect your personal information from unauthorized access, use, or disclosure.
        <br />For example, I store the personal information you provide on computer systems with limited access that are located in facilities to which access is limited.
        <br />For sites to which you login, it is your responsibility to ensure the security of your password and not to reveal this information to others.
        <br />When you move around a site to which you have logged in, or from one site to another that uses the same login mechanism,
        <br />I verify your identity by means of an encrypted cookie placed on your machine.
    </p>

    <h4>Enforcement of this privacy statement</h4>

    <p>
        If you have questions regarding this statement or our handling of your personal information, please contact me.
        <br />I will promptly address your concern and strive to reach a satisfactory resolution.
    </p>

    <teleport to="#pageTitle">Privacy Statement</teleport>
</template>

<script>
export default {
    name: "PrivacyPage"
};
</script>