export default [
    {
        title: "ScaleFocus",
        description: "2021 Jul - Present | System Architect <br/> 2016 Jul - 2021 Jun | Technical Lead",
        link: "https://www.scalefocus.com/",
        items: [
            {
                name: "Social platform for an American photography, photography products, and image sharing company.",
                tech: ".NET Framework 4.6, ASP.NET MVC, MSSQL, Entity Framework 6 Code First and Migrations, WCF, Windows Services, Simple Injector, Telerik Kendo MVC"
            },
            {
                name: "Thebigword IMS Direct - ASP.NET MVC enterprise application",
                tech: ".NET Framework 4.6, ASP.NET MVC, MSSQL, Entity Framework 6 Code First and Migrations, WCF, Windows Services, Simple Injector, Telerik Kendo MVC"
            },
            {
                name: "Code Focus - ASP.NET MVC Core application",
                tech: ".NET Core 1.1, ASP.NET MVC Core, MSSQL"
            },
            {
                name: "ABN Amro Banchmarktool - ASP.NET MVC Core application",
                tech: ".NET Core 1.1, ASP.NET MVC Core, WCF Client, Rest Client, jQuery"
            },
            {
                name: "Thebigword TMS - ASP.NET MVC enterprise application",
                tech: ".NET Framework 4.6, ASP.NET MVC, MSSQL, Entity Framework 6 Code First and Migrations, WCF, Windows Services, Simple Injector, Telerik Kendo MVC"
            }
        ]
    },
    {
        title: "MentorMate",
        description: "2015 Dec - 2016 Jul | Software Development Lead <br/> 2012 Nov - 2015 Nov | Senior Software Developer",
        link: "https://www.mentormate.com/",
        items: [
            {
                name: "MartinBros ERP &amp; B2B - B2B and ERP enterprise application",
                tech: "Java 8 EE, MSSQL, MySQL, jQuery, KnockOutJS, Dynamic Reports"
            },
            {
                name: "ManagerMate - REST services data collecting and database updates",
                tech: ".NET Framework 4.5, Entity Framework 4, ASP.NET MVC, ASP.NET Web API, MSSQL, jQuery"
            },
            {
                name: "Benefit Analysis Tool for Principal Financial Group - web application for employee benefit analysis",
                tech: ".NET Framework 4.5, Entity Framework 3.5, ASP.NET MVC, MSSQL, jQuery, iTextSharp"
            },
            {
                name: "AAN Concussions QuickCheck - a mobile hybrid application for iOS and Android",
                tech: "Apache Cordova, jQuery, jQuery UI, jQuery Mobile, KnockOutJS"
            }
        ]
    },
    {
        title: "SEEEMS.ApS",
        description: "2008 Jun - 2012 Oct | Senior Software Developer",
        link: "https://www.seeems.com/",
        items: [
            {
                name: "SEEEMS.Framework - a framework for extending and customizing SEEEMS.CMS",
                tech: ".NET Framework 4.0, ASP.NET WebForms, ASP.NET MVC, jQuery, jQuery UI"
            },
            {
                name: "SEEEMS.CMS Rebuild - porting and extending SEEEMS ASP content management system to .NET Framework",
                tech: ".NET Framework 4.0, ASP.NET WebForms, ASP.NET MVC, jQuery, jQuery UI"
            },
            {
                name: "Feedback System - custom feedback and survey building framework",
                tech: ".NET Framework 3.5, ASP.NET WebForms, MSSQL"
            }
        ]
    },
    {
        title: "Weblancers.DK",
        description: "2006 Mar - 2008 May | Senior Software Developer",
        link: "https://www.weblancers.dk/",
        items: [
            {
                name: "ASP.NET WebForms - various ASP.NET WebForms web sites development",
                tech: ".NET Framework 2.0, 3.0, 3.5, ASP.NET WebForms, MSSQL"
            },
            {
                name: "Recipe Manager - farmers feeding and recipe planing app",
                tech: "Visual C++ (including ATL, MFC, OLE DB Templates), MySQL"
            }
        ]
    },
    {
        title: "ConsultCommerce",
        description: "2005 May - 2006 Feb | Senior Software Developer",
        link: "https://www.consultcommerce.com/",
        items: [
            {
                name: "E-Commerce Website - custom e-commerce web application",
                tech: ".NET Framework 2.0, APS.NET WebForms, MSSQL"
            },
            {
                name: "Custom CRM web application - custom CRM web application",
                tech: ".NET Framework 2.0, APS.NET WebForms, MSSQL"
            },
            {
                name: ".NET libraries - .NET / ASP.NET WebForms custom control libraries",
                tech: ".NET Framework 2.0, APS.NET WebForms"
            }
        ]
    },
    {
        title: "City Wireless Network - Berlin",
        description: "2004 Dec - 2005 Apr | Software Developer",
        link: "https://www.visitberlin.de/en/public-wi-fi-berlin",
        items: [
            {
                name: "CWN Portal - enterprise system for secure wireless client access",
                tech: ".NET Framework 1.1, ASP.NET WebForms, Windows 2003 Enterprise Servers"
            }
        ]
    },
    {
        title: "Sogodi (RISCO Group)",
        description: "2004 Mar - 2004 Oct | Software Developer",
        link: "https://www.google.com/search?q=sogodi&biw=1920&bih=929&sxsrf=AOaemvJgEwH70yk-xqZMWen8bqS9DpQNnw%3A1641486786854&ei=whnXYeyzM6KGxc8Py7CT4AM&ved=0ahUKEwjsnNXmxp31AhUiQ_EDHUvYBDwQ4dUDCA4&uact=5&oq=sogodi&gs_lcp=Cgdnd3Mtd2l6EAMyBggjECcQEzIHCAAQChDLATIHCAAQChDLATIHCAAQgAQQCjIGCAAQBRAeOgQIIxAnOgsIABCABBCxAxCDAToICAAQgAQQsQM6BQguEIAEOhEILhCABBCxAxCDARDHARCvAToOCC4QgAQQsQMQxwEQ0QM6CAguEIAEELEDOg4ILhCABBCxAxDHARCvAToFCAAQgAQ6CwguEIAEEMcBENEDOgsILhCABBDHARCvAToFCC4QywE6BQgAEMsBSgQIQRgBSgQIRhgAUIQIWJsRYKoWaAJwAHgAgAGAAYgBvgWSAQMwLjaYAQCgAQHAAQE&sclient=gws-wiz",
        items: [
            {
                name: "ELAS Server / SPPX Services - smart security system feedback collection server and addon services",
                tech: ".NET Framework 1.1, ASP.NET WebForms, Web Services, SOAP"
            }
        ]
    },
    {
        title: "Finsoft (Fincore)",
        description: "2000 Apr - 2004 Feb | Software Developer",
        link: "http://www.fincore.com/",
        items: [
            {
                name: "Collaboration Business Portal Workflow Editor - workflow editor over PowerPoint COM libraries",
                tech: ".NET Framework 1.1, ASP.NET WebForms, PowerPoint, COM"
            },
            {
                name: "Collaboration Business Portal Admin - admin web application for managing portal data",
                tech: ".NET Framework 1.1, ASP.NET WebForms"
            },
            {
                name: "Reuters Feed Service - reuters feeds integration to financial application",
                tech: "JDBC, Oracle SQLJ, Oracle JPublisher, Java Mail"
            },
            {
                name: "MarginMaker FirePay Integration - payment provider integration to a financial application",
                tech: "JDBC, Oracle Java Stored Procedures, Oracle SQLJ, Oracle SQLJ Object Types, Oracle JPublisher"
            },
            {
                name: "NPI ADO.NET Provider - ADO.NET provider over publish/subscribe protocol",
                tech: ".NET Framework 1.0, ADO.NET, C++"
            },
            {
                name: "Java Client Audio Media Managing System - java applet client for audio media managing",
                tech: "Java 1.3, Java Applet"
            },
            {
                name: "Windows Audio Media Managing System - windows applications and libraries for managing and encoding audio media",
                tech: ".NET Framework 1.0, Windows Forms, Porting QuickTime SDK C code to .NET library"
            },
            {
                name: "Music Promotion Web Portal - music promotion and feedback collecting portal",
                tech: "Java EE 1.3, Servlet API, JSP, XSLT/Cocoon</em>, HTML, CSS, JavaScript"
            },
            {
                name: "Finsoft Wave Market Map - treemap financial data visualization applet",
                tech: "Java 1.3, Java Applet"
            }
        ]
    },
    {
        title: "Slit Ltd",
        description: "1998 Jun - 2000 Mar | Software Developer",
        link: "",
        items: [
            {
                name: "Help Desk and Tickets Processing - custom windows desktop and network help desk solution",
                tech: "Borland Delphi, Visual Component Library (VCL), Pervasive(Btrieve) Database, Novell NetWare"
            }
        ]
    },
    {
        title: "English Language Study - London",
        description: "1997 Apr - 1998 May | Studying English and part-time working as a student",
        link: ""
    },
    {
        title: "Face Design Ltd",
        description: "1995 Apr - 1997 Mar | Co-founder of advertising company",
        link: "",
        items: [
            {
                name: "Graphic and computer design of various advertising materials",
                tech: "Windows 95, Corel Draw, Photo Shop"
            }
        ]
    },
    {
        title: "Avalon Design Ltd",
        description: "1993 Jan - 1995 Feb | Computer designer",
        link: "",
        items: [
            {
                name: "Graphic and computer design of various advertising materials",
                tech: "Windows 3.1, Corel Draw"
            }
        ]
    }
];