export default {
    data() {
        return {
            emailAddress: "velio.ivanov@gmail.com",
            emailAddressRef: "mailto:velio.ivanov@gmail.com",
            pages: [
                {
                    name: "Home",
                    url: "/"
                },
                {
                    name: "Projects",
                    url: "/projects"
                },
                {
                    name: "Experience",
                    url: "/experience"
                },
                {
                    name: "Skills",
                    url: "/skills"
                }
            ]
        }
    }
};