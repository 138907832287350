export default [
    {
        name: "Technologies",
        items: [
            "C#, Java, VB.NET, SQL(T-SQL), F#, C++, C, VB, Pascal",
            "HTML5, CSS3, JavaScript (jQuery, jQuery UI, jQuery Mobile, KnockOut JS, jQuery dataTables, AJAX, JSON)",
            "Microsoft .NET Framework 1.0 +, ASP.NET/ASP, APS.NET MVC, ASP.NET WEB API, ASP.NET WebForms, Entity Framework, MEF, ADO.NET/ADO, Windows Services, Windows PowerShell, Visual Studio Extensions",
            "Java EE: Java 7 &amp; 8, JPA, CDI, EJB, Servlet API, JSP, JAX-RS (Jersey)",
            "MSSQL 2000 +, MySQL, Redis, Oracle, Interbase/Firebird, Pervasive/Btrieve"
        ]
    },
    {
        name: "Tools",
        items: [
            "<a href='https://www.visualstudio.com/'>Visual Studio</a> (all versions back to Visual Studio 6.0)",
            "<a href='https://code.visualstudio.com/'>Visual Studio Code</a>",
            "<a href='http://www.eclipse.org/'>Eclipse</a> (Mars Luna, Kepler, Juno)",
            "<a href='http://www.red-gate.com/products/dotnet-development/ants-performance-profiler/'>ANTS Performance Profiler</a>",
            "<a href='https://www.ej-technologies.com/products/jprofiler/overview.html'>JProfiler</a>",
            "<a href='https://zeroturnaround.com/software/jrebel/'>JRebel</a>"
        ]
    },
    {
        name: "Source Controls",
        items: [
            "Git",
            "Mercurial",
            "SVN",
            "Perforce",
            "MS TFS",
            "Source Safe"            
        ]
    },
    {
        name: "Methodologies",
        items: [
            "<a href='https://en.wikipedia.org/wiki/Agile_software_development' target='_blank'>Agile software development</a>",
            "<a href='https://en.wikipedia.org/wiki/Scrum_(software_development)' target='_blank'>Scrum (software development)</a>",
            "<a href='https://en.wikipedia.org/wiki/Kanban_(development)' target='_blank'>Kanban (development)</a>",
            "<a href='https://www.atlassian.com/software/jira' target='_blank'>Jira Software</a>",
            "<a href='https://www.pivotaltracker.com/' target='_blank'>Pivotal Tracker</a>",
            "<a href='https://www.apptio.com/products/targetprocess/' target='_blank'>Apptio Targetprocess</a>",
            "<a href='https://en.wikipedia.org/wiki/Extreme_programming' target='_blank'>Extreme Programming</a>",
            "<a href='https://en.wikipedia.org/wiki/Rational_Unified_Process' target='_blank'>Rational Unified Process</a>"
        ]
    },
    {
        name: "Others",
        items: [
            '<a href="http://lefthandedgoat.github.io/canopy/index.html">Canopy</a>',
            '<a href="http://www.seleniumhq.org/">Selenium</a> - UI authomation testing',
            '<a href="https://cordova.apache.org/">Apache Cordova</a> - Hybrid mobile apps with HTML, CSS &amp; JS',
            '<a href="http://www.dynamicreports.org/">Dynamic Reports</a>',
            '<a href="https://reportserver.net">Jasper Reports</a> - Java reporting tools',
            '<a href="http://itextpdf.com/">iText</a>',
            '<a href="https://github.com/itext/itextsharp">iTextSharp</a> - Dynamic PDF generation and manipulation',
            'Adobe Photoshop',
            'Macromedia Fireworks'
        ]
    },
    {
        name: "Human Languages",
        items: [
            'English - fluent',
            'Russian - fluent',
            'Greek - elementary',
            'Bulgarian - native'
        ]
    }
];