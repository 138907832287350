import { createRouter, createWebHistory } from 'vue-router';
import BookmarksPageVue from '../bookmarks/BookmarksPage.vue';
import ExperiencePage from '../experience/ExperiencePage.vue';
import HomePage from '../home/HomePage.vue';
import PrivacyPage from '../privacy/PrivacyPage.vue';
import ProjectsPage from '../projects/ProjectsPage.vue';
import SkillsPage from '../skills/SkillsPage.vue';
import VideosPageVue from '../videos/VideosPage.vue';

export default createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: '/',
            name: 'Home',
            component: HomePage
        },
        {
            path: '/privacy',
            name: 'Privacy',
            component: PrivacyPage
        },
        {
            path: '/projects',
            name: 'Projects',
            component: ProjectsPage
        },
        {
            path: '/experience',
            name: 'Experience',
            component: ExperiencePage
        },
        {
            path: '/skills',
            name: 'Skills',
            component: SkillsPage
        },
        {
            path: '/videos',
            name: 'Videos',
            component: VideosPageVue
        },
        {
            path: '/bookmarks',
            name: 'Bookmarks',
            component: BookmarksPageVue
        }
    ]
});