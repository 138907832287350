<template>
    <h2>Hi I'm Velio</h2>

    <p>
        <em>
            I spent
            <span experience>{{ years }}&#x2B;</span> years of programming and software
            development on a wide range of technologies with a knowledge in latest market
            trends.
        </em>
    </p>

    <p>
        That empowers me to build strong background in the programming and software
        development.
        <br />My passion in software development drive me to become a founder and contributing to
        open-source projects.
        <br />I am a team worker who is able to adapt in highly dynamic and changing situations,
        and the new industry standards.
    </p>

    <p>
        Right now, I work as .NET Technical Lead with the awesome people at
        <a
            href="http://www.scalefocus.com/"
        >ScaleFocus</a> on number of exciting .NET and
        .NET Core projects.
        <br />Prior to joining the
        <a href="http://www.scalefocus.com/">ScaleFocus</a> team, I was
        Software Development Lead at
        <a
            href="https://www.mentormate.bg/"
        >MentorMate-Bulgaria</a> working on a long term
        Java EE project.        
        <br />On my spare time I work on a number of open source projects which you can find
        <a
            href="https://github.com/velyo"
        >under my GitHub account</a>
    </p>

    <p>
        I am living at the ancient and beautiful city of
        <a
            href="http://en.wikipedia.org/wiki/Plovdiv"
            target="_blank"
        >Plovdiv</a>,
        <a href="http://en.wikipedia.org/wiki/Bulgaria" target="_blank">Bulgaria</a>.
        <br />On September 5, 2014, Plovdiv was selected as the Bulgarian host of the
        <a
            href="http://plovdiv2019.eu/en"
        >European Capital of Culture 2019</a>.
    </p>

    <p>
        I am mad about programming, but when I'm not doing magic on my desk, You can find me
        spending my time with my family, go out fishing, brewing beer and playing soccer.
    </p>

    <teleport to="#pageTitle">Welcome</teleport>
    <teleport to="#pageSubTitle">About Me</teleport>
</template>

<script>
export default {
    name: "HomePage",
    data() {
        var millis = Date.now();
        var date = new Date(millis);
        return {
            years: date.getUTCFullYear() - 1998
        }
    },
};
</script>