<template>
    <div class="l-header">
        <div class="l-header-h">
            <div class="l-subheader at_top">
                <div class="l-subheader-h i-cf">
                    <div class="w-contacts">
                        <div class="w-contacts-h">
                            <div class="w-contacts-list">
                                <div class="w-contacts-item for_email">
                                    <i class="fa fa-envelope"></i>
                                    <span class="w-contacts-item-name">Email:</span>
                                    <span class="w-contacts-item-value" site-email>
                                        <a :href="emailAddressRef">{{ emailAddress }}</a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="w-socials">
                        <div class="w-socials-h">
                            <div class="w-socials-list">
                                <SocialLinks></SocialLinks>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="l-subheader at_middle">
                <div class="l-subheader-h i-cf">
                    <!-- LOGO -->
                    <div class="w-logo">
                        <div class="w-logo-h">
                            <a class="w-logo-link" href="/">
                                <img class="w-logo-img" src="/img/me.jpg" alt="Me" />
                                <span class="w-logo-title">
                                    <span class="w-logo-title-h">Velio Ivanov</span>
                                </span>
                            </a>
                        </div>
                    </div>

                    <!-- SEARCH -->

                    <!-- NAV -->
                    <nav class="w-nav">
                        <div class="w-nav-h">
                            <div class="w-nav-control">
                                <i class="fa fa-bars"></i>
                            </div>
                            <div class="w-nav-list layout_hor width_auto float_right level_1">
                                <div class="w-nav-list-h">
                                    <div
                                        v-for="page in pages"
                                        :key="page.url"
                                        class="w-nav-item level_1"
                                    >
                                        <div class="w-nav-item-h">
                                            <router-link
                                                :to="page.url"
                                                class="w-nav-anchor level_1"
                                            >
                                                <span class="w-nav-title">{{ page.name }}</span>
                                                <span class="w-nav-hint"></span>
                                            </router-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import sharedMixin from "./shared-mixin";
import SocialLinks from "./SocialLinks.vue";

export default {
    name: "Header",
    mixins: [sharedMixin],
    components: { SocialLinks }
};
</script>