<template>
    <teleport to="#pageTitle">Experience</teleport>
    <teleport to="#pageSubTitle">My work history</teleport>

    <ExperienceItem v-for="item in experience" :key="item.title" :experience="item"></ExperienceItem>
    
</template>

<script>
import experience from "../data/experience";
import ExperienceItem from "./ExperienceItem.vue";

export default {
    name: "ExperiencePage",
    components: { ExperienceItem },
    data() {
        return { experience };
    }
};
</script>