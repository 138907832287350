<template>
    <p>
        <strong>{{ skill.name }}</strong>
    </p>

    <ul>
        <li v-for="item in skill.items" :key="item" v-html="item"></li>
    </ul>

    <div class="g-hr">
        <span class="g-hr-h">
            <i class="fa fa-star"></i>
        </span>
    </div>
</template>

<script>

export default {
    name: "SkillsItem",
    props: ["skill"]
};
</script>
